import 'bootstrap';
import 'jquery';
import 'metisMenu';
import AccessPolicyController from 'oswedev/dist/shared/modules/AccessPolicy/AccessPolicyController';
import LocaleManager from 'oswedev/dist/shared/tools/LocaleManager';
import AccessPolicyLoginVueModule from 'oswedev/dist/vuejsclient/login/AccessPolicy/AccessPolicyLoginVueModule';
import LoginVueMain from 'oswedev/dist/vuejsclient/login/main/LoginVueMain';
import DAOStore from 'oswedev/dist/vuejsclient/ts/components/dao/store/DaoStore';
import OnPageTranslationStore from 'oswedev/dist/vuejsclient/ts/components/OnPageTranslation/store/OnPageTranslationStore';
import AppVuexStoreManager from 'oswedev/dist/vuejsclient/ts/store/AppVuexStoreManager';
import VueAppBase from 'oswedev/dist/vuejsclient/VueAppBase';
import 'select2';
import ModuleMalerbaRehaDBController from '../vuejsclient/public/pwa/ModuleMalerbaRehaDBController';
import LoginAppController from './LoginAppController';
import './scss/login-skin.scss';
import InitializeLoginModulesDatas from './ts/generated/InitializeLoginModulesDatas';
let version = require('../../package.json').version;


export default class LoginApp extends VueAppBase {

    public static getInstance(): LoginApp {
        if (!LoginApp.instance) {
            LoginApp.instance = new LoginApp();
        }
        return LoginApp.instance;
    }

    private static instance: LoginApp;

    protected constructor() {
        super(LoginAppController.getInstance(), InitializeLoginModulesDatas);
    }

    public createVueMain() {

        AppVuexStoreManager.getInstance().registerModule(DAOStore.getInstance());
        AppVuexStoreManager.getInstance().registerModule(OnPageTranslationStore.getInstance());

        AppVuexStoreManager.getInstance().configure_store();

        // La compilation plante sur ces options mais impossible de retrouver la source des ces options....
        const options = {
            router: (this as VueAppBase).vueRouter,
            store: AppVuexStoreManager.getInstance().appVuexStore,
            i18n: LocaleManager.getInstance().i18n
        } as any;

        AccessPolicyController.getInstance().hook_user_recover = this.user_recover.bind(this);
        AccessPolicyController.getInstance().hook_user_signin = this.user_signin.bind(this);

        return new LoginVueMain(options);
    }

    protected async initializeVueAppModulesDatas() {
        AccessPolicyLoginVueModule.getInstance();

        localStorage.removeItem('pdfjs.history');

        await ModuleMalerbaRehaDBController.getInstance().initialize_pwa(
            $,
            true,
            '/public/client-sw.' + version + '.js'
        );
    }

    private async user_recover() {
        window.open("https://www.mymalerba.fr/login#/recover", '_blank');
    }

    private async user_signin() {
        window.open("https://www.mymalerba.fr/login#/signin", '_blank');
    }
}

LoginApp.getInstance().runApp();
